<template>
  <div class="stage__wrapper" @click.stop="clickStage">
    <div class="stage__content">
      <h1 class="stage__title" :style="titleStyles" v-html="title" />
      <div
        class="bubble__wrapper"
        :class="{ 'bubble__wrapper--fncm': !$brand.isFonic() }"
      >
        <SpeechBubble
          class="stage__speech-bubble"
          :max-width="$breakpoint.fromTablet() ? '14.7rem' : undefined"
          :left="$breakpoint.upToTablet()"
          :right="$breakpoint.fromTablet()"
        >
          <p class="speech-bubble__title">Starkes Netz</p>
          <div class="speech-bubble__text-wrapper">
            <Icon
              name="checkmark"
              :height="$breakpoint.fromTablet() ? '23' : '13'"
              :color="checkmarkColor"
              up="6"
            />

            <p class="speech-bubble__text">
              Internet im Telefónica Mobilfunknetz
            </p>
          </div>
        </SpeechBubble>
        <SpeechBubble
          class="stage__speech-bubble"
          :max-width="$breakpoint.fromTablet() ? '14.2rem' : undefined"
          :top="$breakpoint.fromTablet()"
          :left="$breakpoint.upToTablet()"
          :right="$breakpoint.fromTablet()"
        >
          <p class="speech-bubble__title">Jederzeit flexibel</p>
          <div class="speech-bubble__text-wrapper">
            <Icon
              name="checkmark"
              :height="$breakpoint.fromTablet() ? '23' : '13'"
              :color="checkmarkColor"
              up="6"
            />

            <p class="speech-bubble__text">
              100% flexibel, ohne Vertragsbindung und Mindestumsatz
            </p>
          </div>
        </SpeechBubble>
        <SpeechBubble
          class="stage__speech-bubble"
          :max-width="$breakpoint.fromTablet() ? '13.8rem' : undefined"
          left
          :top="$breakpoint.upToTablet()"
        >
          <p class="speech-bubble__title">Top Tarife</p>
          <div class="speech-bubble__text-wrapper">
            <Icon
              name="checkmark"
              :height="$breakpoint.fromTablet() ? '23' : '13'"
              :color="checkmarkColor"
              up="6"
            />
            <p class="speech-bubble__text">
              Unbeschwert surfen und telefonieren ab 9,99 € / 4 Wochen
            </p>
          </div>
        </SpeechBubble>
      </div>
      <div class="button">
        <ButtonComponent
          button-type="highlight"
          :href="href"
          :clickhandler="clickStage"
          >{{ buttonText }}</ButtonComponent
        >
      </div>
    </div>
    <div class="image_component" :style="inlineSvgStyles">
      <InlineSvg
        v-if="$breakpoint.fromTablet()"
        class="image__wrapper stage__bigscreen"
        :style="inlineSvgStyles"
        alt=""
        :src="stageVisual"
        :animate-elements="animateElements"
      />

      <InlineSvg
        v-if="$breakpoint.upToTablet()"
        class="image__wrapper stage__small"
        :style="inlineSvgStyles"
        alt=""
        :src="stageVisualMobile"
        :animate-elements="animateElements"
      />
    </div>
  </div>
</template>

<script>
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import Icon from '../../icon/icon.vue'
import InlineSvg from '../../inline_svg/inline_svg.vue'
import SpeechBubble from 'src/components/common/speech_bubble/speech_bubble.vue'
export default {
  name: 'BackgroundTitleSpeechbubble',
  components: {
    SpeechBubble,
    InlineSvg,
    ButtonComponent,
    Icon
  },
  props: {
    title: {
      type: String,
      default: undefined
    },
    color: {
      type: String,
      default: undefined
    },
    colorCheckmark: {
      type: String,
      default: undefined
    },
    buttonText: {
      type: String,
      default: 'Zu den Tarifen'
    },
    href: {
      type: String,
      required: true
    },
    stageVisual: {
      type: String,
      required: true
    },
    stageVisualMobile: {
      type: String,
      required: true
    },
    animateElements: {
      type: Array,
      default: () => []
    },
    svgViewBoxHeight: {
      type: Number,
      default: 540
    },
    titleMarginTop: {
      type: String,
      default: undefined
    },
    fontSize: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      clickAction: undefined,
      inlineSvgStyles: {
        'min-height': `${this.svgViewBoxHeight}px`
      },
      titleStyles: {
        color: this.color ? this.color : 'var(--root-color)',
        'font-size': this.fontSize ? this.fontSize : '',
        'margin-top': this.titleMarginTop ? this.titleMarginTop : ''
      }
    }
  },
  computed: {
    checkmarkColor() {
      return this.colorCheckmark !== undefined
        ? this.colorCheckmark
        : this.color
    }
  },
  mounted() {
    if (this.$attrs.onClick) {
      this.clickAction = this.$attrs.onClick
    }
  },
  methods: {
    clickStage() {
      this.$router.push({ name: 'PortalTariffOverview' })
    }
  }
}
</script>

<style lang="scss" scoped>
$up-to-430px: max-width 430px;
$from-430px: min-width 431px;
$from-1100px: min-width 1100px;

.stage__wrapper {
  margin-top: 45px;
  width: 100%;
  position: relative;
  flex-direction: column;
  align-items: center;
  display: flex;
  cursor: pointer;

  @include breakpoint($from-desktop) {
    margin-top: 0;
  }
}

.image__wrapper {
  overflow: hidden;
  width: 100vw;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 540px;

  @include breakpoint($up-to-tablet) {
    max-height: 430px;
  }

  @include breakpoint($from-tablet) {
    :deep(img) {
      max-width: none;
    }
  }

  @include ie-only {
    @include breakpoint($up-to-tablet) {
      :deep(img) {
        height: 100%;
      }
    }
  }
}

.stage__content {
  @include full-width;
  @include font-normal;

  z-index: 1;
  height: 100%;
  padding: 0 20px;
  position: absolute;
  top: 0;
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  overflow: hidden;

  @include ie-only {
    right: 0;
    left: 0;
  }

  @include breakpoint($up-to-desktop) {
    justify-content: flex-end;
  }

  @include breakpoint($up-to-tablet) {
    padding: 0 10px;
  }
}

.stage__title {
  @include font-bold;

  line-height: 1;
  font-size: min(2.3rem, 52px);
  padding: 0 13px;

  @include fonic-mobile {
    font-size: min(1.7rem, 38px);
  }

  @include breakpoint($up-to-430px) {
    font-size: min(1.6rem, 38px);
  }

  @include breakpoint($up-to-desktop) {
    margin-top: 25px;
  }

  @include breakpoint($from-tablet) {
    font-size: min(2.2rem, 50px);
    align-self: center;
    padding: 0 5px;
  }

  @include breakpoint($from-desktop) {
    font-size: min(2.5rem, 50px);
    margin-top: 170px;
    max-width: 985px;
  }

  @include breakpoint($from-1100px) {
    margin-top: 160px;
  }
}

.button {
  margin-top: 0;
  margin-right: 13.3%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;

  .button-component__highlight {
    min-height: 50px;
    font-size: 1rem;
  }

  @include breakpoint($up-to-430px) {
    margin-top: -24px;
    margin-bottom: 10px;

    .button-component__highlight {
      min-height: 40px;
    }
  }

  @include breakpoint($from-tablet) {
    margin-bottom: 80px;
    max-width: 490px;
    align-self: flex-end;

    .button-component__highlight {
      font-size: 1.3rem;
    }
  }

  @include breakpoint($up-to-desktop) {
    margin-bottom: 15px;
    margin-right: 0;
  }
}

.image_component {
  width: 100%;
}

.bubble__wrapper {
  position: absolute;
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  justify-items: end;
  top: 130px;
  width: calc(100% - 20px);
  margin-left: auto;
  gap: 10px;

  @include breakpoint($from-430px) {
    top: 150px;
  }

  @include breakpoint($from-tablet) {
    grid-template-columns: 1fr 0.5fr 1fr;
    top: 180px;
  }

  @include breakpoint($from-desktop) {
    grid-template-columns: 1fr 0.2fr 1fr;
    top: 265px;
  }
}

.bubble__wrapper--fncm {
  grid-template-columns: 0.7fr 1fr;
  top: 165px;

  @include breakpoint($from-430px) {
    top: 150px;
  }

  @include breakpoint($from-tablet) {
    grid-template-columns: 1fr 0.5fr 1fr;
    top: 180px;
  }

  @include breakpoint($from-desktop) {
    grid-template-columns: 1fr 0.2fr 1fr;
    top: 265px;
  }
}

.speech-bubble__text-wrapper {
  display: flex;
  gap: 7px;
}

.stage__speech-bubble {
  grid-column: 2;

  @include breakpoint($from-tablet) {
    &:nth-child(1) {
      margin-left: auto;
      grid-column: 1;
    }

    &:nth-child(2) {
      margin-left: auto;
      grid-row: 2;
      grid-column: 1;
    }

    &:nth-child(3) {
      grid-column: 3;
      margin-right: auto;
      margin-top: 0;
    }
  }
}

.speech-bubble__title {
  @include font-bold;

  margin: 0;
  font-size: min(0.9rem, 14.5px);

  @include breakpoint($from-tablet) {
    font-size: min(1.2rem, 32px);
  }
}

.speech-bubble__text {
  margin: 0;
  font-size: min(0.8rem, 13px);

  @include breakpoint($from-tablet) {
    font-size: min(1rem, 22px);
  }
}

.stage__bigscreen {
  display: none;

  @include breakpoint($from-tablet) {
    display: inherit;
  }
}

.stage__small {
  @include breakpoint($from-tablet) {
    display: none;
  }
}

p {
  margin-bottom: 0;
}
</style>
