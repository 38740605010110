<template>
  <div class="tariff-picker">
    <h2 class="title">Welcher Tarif passt zu Ihnen?</h2>

    <p class="paragraph">
      Wir machen Ihnen die Entscheidung leicht: Bei Bedarf kann der Tarif
      flexibel gewechselt werden.
    </p>

    <div v-if="$breakpoint.fromDesktop()" class="tariff-picker__content">
      <div role="tablist" class="tabs__header" aria-label="Tarifauswahl">
        <button
          v-for="(tab, index) in tabs"
          :id="`tab-${tab.name}`"
          :key="`tab-${tab.name}`"
          :ref="`tab-${tab.name}`"
          class="tabs__header-element"
          :class="{ active: active === index, [tab.name]: true }"
          :data-cy="`tabs-header-${index}`"
          role="tab"
          :aria-selected="active === index"
          :aria-controls="`tab-panel-${tab.name}`"
          @click="() => showTab(tab)"
        >
          <span class="tab__label">{{ tab.title }}</span>
          <p class="tab__paragraph">{{ tab.subtitle }}</p>
        </button>

        <div
          ref="activeMarker"
          :class="{ right: active === packagesTab.index }"
          class="tabs__active-marker"
        />
      </div>

      <div
        v-show="active === classicTab.index"
        :id="`tab-panel-${classicTab.name}`"
        :ref="`tab-panel-${classicTab.name}`"
        :aria-labelledby="`tab-${classicTab.name}`"
        class="tab__content"
        role="tabpanel"
        tabindex="-1"
      >
        <ClassicTariffCard class="classic-card" />

        <div class="note__wrapper">
          <ImageComponent
            class="arrow"
            :src="imageSrc"
            :alt="`Hinweispfeil auf den zweiten Tab zu den ${$brand.name} Paketen`"
          />
          <p class="note">
            Lieber unbegrenzt<br />surfen und telefonieren zum monatlichen
            Festpreis?
          </p>
        </div>
      </div>

      <div
        v-show="active === packagesTab.index"
        :id="`tab-panel-${packagesTab.name}`"
        :ref="`tab-panel-${packagesTab.name}`"
        :aria-labelledby="`tab-${packagesTab.name}`"
        class="tab__content"
        role="tabpanel"
        tabindex="-1"
      >
        <PackageTariffCards :tariffs="packages" />
      </div>
    </div>

    <div v-else class="tariff-picker__content">
      <h3 class="tab__label">{{ classicTab.title }}</h3>
      <p class="tab__paragraph">{{ classicTab.subtitle }}</p>

      <ClassicTariffCard :heading-level="4" class="classic-card" />

      <h3 class="tab__label">{{ packagesTab.title }}</h3>
      <p class="tab__paragraph">{{ packagesTab.subtitle }}</p>

      <PackageTariffCards :heading-level="4" :tariffs="packages" />
    </div>
  </div>
</template>

<script>
import { DARK } from 'src/components/common/settings/themes.js'
import { mapState } from 'vuex'
import { nextTick } from 'vue'
import ClassicTariffCard from './components/classic.vue'
import ImageComponent from 'src/components/common/image/image.vue'
import PackageTariffCards from './components/packages.vue'
import arrow from './components/resources/arrow.webp'
import arrowDark from './components/resources/arrow_dark.webp'

const CLASSIC = 0
const PACKAGES = 1

export default {
  name: 'PortalHomeTariffPicker',
  components: {
    ClassicTariffCard,
    PackageTariffCards,
    ImageComponent
  },
  data() {
    return {
      active: CLASSIC,
      classicTab: {
        index: CLASSIC,
        name: 'classic',
        title: 'Classic',
        subtitle: 'Zahlen Sie nur, was Sie verbrauchen'
      },
      packagesTab: {
        index: PACKAGES,
        name: 'packages',
        title: 'Pakete',
        subtitle: 'Monatliches Abonnement'
      },
      packages: [
        {
          name: 'Smart S',
          slug: 'smart-s',
          includedData: 10,
          price: 9.99,
          footnoteId: this.$brand.isFonic() ? 3 : 38
        },
        {
          name: 'Smart M',
          slug: 'smart-m',
          includedData: 20,
          price: 14.99,
          footnoteId: this.$brand.isFonic() ? 25 : 36
        },
        {
          name: 'Smart L',
          slug: 'smart-l',
          includedData: 30,
          price: 19.99,
          footnoteId: this.$brand.isFonic() ? 5 : 37
        }
      ],
      arrow
    }
  },
  computed: {
    ...mapState({
      isHighContrastModeActive: state => state.settings.theme === DARK
    }),
    tabs() {
      return [this.classicTab, this.packagesTab]
    },
    imageSrc() {
      return this.isHighContrastModeActive ? arrowDark : arrow
    }
  },
  methods: {
    showTab(tab) {
      this.active = tab.index
      const tabPanel = this.$refs[`tab-panel-${tab.name}`]

      nextTick(() => {
        tabPanel?.focus()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tariff-picker {
  padding-bottom: 50px;

  .title {
    font-size: min(2rem, 50px);
  }

  .paragraph {
    margin: 20px 0 56px;
  }

  @include breakpoint($up-to-desktop) {
    padding: 0;

    .paragraph {
      margin: 20px 0 48px;
    }
  }
}

.tabs__header {
  position: relative;
  display: flex;
}

.tabs__header::before {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  background: $c_neutral-200;
  height: 4px;
  border-radius: 2px;
}

.tabs__header-element {
  padding: 24px 24px 0;
  flex-grow: 1;
  text-align: start;
}

.tabs__active-marker {
  position: absolute;
  bottom: -10px;
  left: 0;
  background: var(--tariff-picker_active-marker);
  height: 8px;
  width: 50%;
  border-radius: 4px;
  transition: left ease-in-out 300ms, background-color ease-in-out 300ms;
}

.tabs__header-element.packages {
  &.active ~ .tabs__active-marker {
    left: 50%;
  }

  &:not(.active):hover ~ .tabs__active-marker {
    background: var(--tariff-picker_active-marker--hover);
    left: 5%;
  }
}

.tabs__header-element.classic:not(.active):hover ~ .tabs__active-marker {
  background: var(--tariff-picker_active-marker--hover);
  left: 45%;
}

.tab__label {
  font-size: min(1.5rem, 40px);

  @include font-bold;
}

.tab__paragraph {
  color: var(--root-color-muted);
  line-height: 1;
  margin: 8px 0 24px;
}

.tab__content {
  margin-top: 32px;
  display: flex;
}

.classic-card {
  margin-bottom: 48px;

  @include breakpoint($from-desktop) {
    margin-bottom: 0;
    width: 60%;
    min-width: 500px;
  }
}

.note__wrapper {
  flex: 0 1 auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.arrow {
  align-self: flex-end;
  margin-bottom: 30px;
}

.note {
  color: var(--tariff-picker_note);
  text-align: center;
  font-family: 'Light Stories', Arial, Helvetica, sans-serif;
  font-size: min(1.5rem, 48px);
  line-height: 1.4;
  letter-spacing: 1.2px;
  max-width: 350px;
}
</style>
