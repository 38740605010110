<template>
  <ul v-if="$breakpoint.fromDesktop()" class="packages__wrapper">
    <li
      v-for="(tariff, index) in tariffs"
      :key="`package-${index}`"
      class="card"
    >
      <div class="card__header">
        <component :is="`h${headingLevel}`" class="card__title"
          >{{ tariff.includedData }} GB</component
        >
        <p class="card__subtitle" :data-cy="`tariff-name-${index}`">
          {{ `${$brand.name} ${tariff.name}` }}
        </p>

        <Price
          class="price__wrapper"
          :price="tariff.price"
          :footnote="tariff.footnoteId"
          period="4 Wochen"
          :initial-cost="'9,99'"
        />
      </div>

      <ul class="card__content">
        <li v-for="(item, j) in list" :key="`listitem-${j}`">
          <Icon :name="item.icon" width="20" height="20" />{{ item.text }}
        </li>
      </ul>

      <div class="card__action">
        <AccessibleButton
          :button-type="isHighContrastModeActive ? 'highlight' : 'primary'"
          class="sts__button"
          :aria-label="`${$brand.name} ${tariff.name}, Das will ich`"
          :clickhandler="() => $router.push(paths[index])"
          :data-cy="`sts-button-${index}`"
          :href="$router.resolve(paths[index]).href"
        >
          Das will ich
        </AccessibleButton>
      </div>
    </li>
  </ul>

  <div v-else class="card" :class="`card-${current}`">
    <div role="tablist" class="card__tabs" aria-label="Tarifpakete">
      <div class="card__tabs-header">
        <button
          v-for="(tariff, index) in tariffs"
          :id="`tab-${tariff.slug}`"
          :key="`tab-${tariff.slug}`"
          :ref="`tab-${tariff.slug}`"
          class="card__tabs-button"
          :class="{ active: current === index }"
          role="tab"
          :aria-selected="current === index"
          :aria-controls="`tab-panel-${tariff.slug}`"
          @click="() => switchTariff(tariff, index)"
        >
          {{ tariff.includedData }} GB
        </button>
      </div>
    </div>

    <div
      v-for="(tariff, index) in tariffs"
      v-show="current === index"
      :id="`tab-panel-${tariff.slug}`"
      :key="`tab-panel-${tariff.slug}`"
      :ref="`tab-panel-${tariff.slug}`"
      :aria-labelledby="`tab-${tariff.slug}`"
      class="card-wrapper"
      role="tabpanel"
      tabindex="-1"
    >
      <div class="card__header">
        <component :is="`h${headingLevel}`" class="card__title">
          {{ `${$brand.name} ${tariff.name}` }}
        </component>

        <Price
          class="price__wrapper"
          :price="tariff.price"
          :footnote="tariff.footnoteId"
          period="4 Wochen"
          :initial-cost="'9,99'"
        />
      </div>

      <ul class="card__content">
        <li v-for="(item, j) in list" :key="`listitem-${j}`">
          <Icon :name="item.icon" width="20" height="20" />
          {{ item.text }}
        </li>
      </ul>

      <div class="card__action">
        <AccessibleButton
          :button-type="isHighContrastModeActive ? 'highlight' : 'primary'"
          :aria-label="`${$brand.name} ${tariff.name}, Das will ich`"
          :data-cy="`sts-button-${index}`"
          class="sts__button"
          :clickhandler="() => $router.push(paths[current])"
          :href="$router.resolve(paths[current]).href"
        >
          Das will ich
        </AccessibleButton>
      </div>
    </div>
  </div>
</template>

<script>
import { DARK } from 'src/components/common/settings/themes.js'
import { defineAsyncComponent } from 'vue'
import { mapState } from 'vuex'
import { nextTick } from 'vue'
import AccessibleButton from 'src/components/common/input/accessible_button.vue'
import Icon from 'src/components/common/icon/icon.vue'
import Price from 'src/components/common/pack/components/price.vue'

export default {
  name: 'PackageTariffCards',
  components: {
    AccessibleButton,
    Icon,
    Price,
    Footnote: defineAsyncComponent(() =>
      import('src/components/common/footnote/footnote.vue')
    )
  },
  props: {
    tariffs: {
      type: Array,
      default: () => [],
      required: true
    },
    headingLevel: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      current: 0,
      list: [
        { text: 'Mobiles Internet bis zu 50 MBit/s', icon: 'check_circle' },
        { text: 'Allnet-Flate für Telefonie & SMS', icon: 'check_circle' },
        { text: 'Inklusive EU-Roaming', icon: 'check_circle' },
        { text: 'Die ersten 4 Wochen kostenlos', icon: 'present' }
      ]
    }
  },
  computed: {
    ...mapState({
      isHighContrastModeActive: state => state.settings.theme === DARK
    }),
    currentTariff() {
      return this.tariffs[this.current]
    },
    paths() {
      return this.tariffs.map(elem => ({
        name: 'PortalTariffDetails',
        params: { slug: `${this.$brand.code}-${elem.slug}` }
      }))
    }
  },
  methods: {
    switchTariff(tariff, index) {
      if (index < 0 || index >= this.tariffs.length) return

      this.current = index
      const tabPanel = this.$refs[`tab-panel-${tariff.slug}`][0]

      nextTick(() => {
        tabPanel?.focus()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../tariff_card';

.card__header {
  flex-direction: column;

  @include breakpoint($up-to-desktop) {
    padding: 24px 12px;
    flex-direction: column;
    align-items: center;
  }
}

.card {
  @include breakpoint($from-desktop) {
    box-shadow: 0 0 0 20px var(--background-primary);
  }

  @include breakpoint($up-to-desktop) {
    &.card-0 {
      background: linear-gradient(
        342deg,
        var(--tariff-picker_gradient) 30%,
        transparent 34%
      );
    }

    &.card-1 {
      background: linear-gradient(
        342deg,
        var(--tariff-picker_gradient) 50%,
        transparent 54%
      );
    }

    &.card-2 {
      background: linear-gradient(
        342deg,
        var(--tariff-picker_gradient) 70%,
        transparent 74%
      );
    }
  }
}

.packages__wrapper {
  display: flex;
  gap: 30px;
  width: 100%;
  list-style: none;
  padding-left: 0;
  overflow: hidden;

  .card {
    flex-grow: 1;
    flex-basis: 30%;
  }

  @include breakpoint($from-desktop) {
    background: linear-gradient(
      342deg,
      var(--tariff-picker_gradient) 60%,
      transparent 64%
    );
  }
}

.card__tabs-header {
  padding: 24px 12px 0;
  display: flex;
  gap: 5px;
  align-items: center;
  min-height: 102px;
}

.card__tabs-button {
  @include font-normal;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 3px solid var(--button_secondary_border);
  color: var(--button_secondary_label);
  background-color: var(--button_secondary);
  flex-grow: 1;
  min-height: 74px;
  font-size: min(1.25rem, 40px);
  padding-left: 4px;
  padding-right: 4px;
  transition: min-height ease-in-out 250ms, border-color ease-in-out 250ms;

  &:hover {
    background-color: var(--button_secondary_hover);
  }

  &:active {
    background-color: var(--button_secondary_active);
  }

  &:not(.active) {
    border-color: $c_neutral-200;
    min-height: 66px;
  }

  :deep(.button-label) {
    min-width: 0;
  }
}

.card__title {
  @include breakpoint($up-to-desktop) {
    color: var(--root-color-muted);
    margin-bottom: 16px;
  }
}

.card__subtitle {
  margin-bottom: 16px;
}

.price__wrapper {
  align-items: flex-start;
  @include breakpoint($up-to-desktop) {
    flex-direction: column;
    align-items: center;
  }

  :deep(.price) {
    height: calc-rem(45px);

    .euro {
      font-size: calc-rem(56px);
    }

    .cent {
      font-size: 1.5rem;
    }
  }
}

.price {
  @include font-bold;

  margin-right: 8px;
  font-size: min(3rem, 80px);
  line-height: 0.8;

  @include breakpoint($from-desktop) {
    &::before {
      content: '€';

      @include font-bold;

      vertical-align: super;
      font-size: min(1.55rem, 40px);
      margin-right: 8px;
      line-height: 1;
    }
  }

  @include breakpoint($up-to-desktop) {
    &::after {
      content: '€';

      @include font-bold;

      vertical-align: super;
      font-size: min(1.55rem, 40px);
      margin-left: 8px;
      line-height: 1;
    }
  }
}
</style>
