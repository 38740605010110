<template>
  <component
    :is="$breakpoint.fromDesktop() ? 'ul' : 'div'"
    class="card__wrapper"
  >
    <component :is="$breakpoint.fromDesktop() ? 'li' : 'div'" class="card">
      <div class="card__header">
        <div class="title__wrapper">
          <component :is="`h${headingLevel}`" class="card__title"
            >{{ $brand.name }} Classic
          </component>
          <p class="card__subtitle">Guthaben aufladen und verbrauchen</p>
        </div>

        <Price
          v-if="$breakpoint.fromTablet()"
          class="price__wrapper"
          :price="0"
          :footnote="1"
          no-recurring-costs="Keine Grundgebühr"
          :initial-cost="'9,99'"
        />

        <p v-else class="price__wrapper price__wrapper--mobile">
          <span class="classic_price">0</span>
          <span class="price__desc">
            Keine Grundgebühr
            <Footnote class="smart-triple-select__footnote" :footnote-id="1" />
          </span>
          <span class="price__desc">9,99 € einmalig </span>
        </p>
      </div>
      <ul class="card__content">
        <li>
          <Icon name="check_circle" :height="iconHeight" />
          9 Cent pro SMS
        </li>
        <li>
          <Icon name="check_circle" :height="iconHeight" />
          9 Cent pro Minute
        </li>
        <li>
          <Icon name="check_circle" :height="iconHeight" />
          24 Cent pro MB Datenvolumen
        </li>
        <li>
          <Icon name="present" :height="iconHeight" />
          Erhalte 10 Euro Startguthaben
        </li>
      </ul>
      <div class="card__action">
        <AccessibleButton
          :clickhandler="() => $router.push(path)"
          :href="$router.resolve(path).href"
          :aria-label="`${$brand.name} Classic, Das will ich`"
          :button-type="isHighContrastModeActive ? 'highlight' : 'primary'"
          data-cy="classic--button"
        >
          Das will ich
        </AccessibleButton>
      </div>
    </component>
  </component>
</template>

<script>
import { DARK } from 'src/components/common/settings/themes.js'
import { defineAsyncComponent } from 'vue'
import { mapState } from 'vuex'
import AccessibleButton from 'src/components/common/input/accessible_button.vue'
import Icon from 'src/components/common/icon/icon.vue'
import Price from 'src/components/common/pack/components/price.vue'

export default {
  name: 'ClassicTariffCard',
  components: {
    AccessibleButton,
    Icon,
    Price,
    Footnote: defineAsyncComponent(() =>
      import('src/components/common/footnote/footnote.vue')
    )
  },
  props: {
    headingLevel: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      slug: `${this.$brand.code}-classic`
    }
  },
  computed: {
    ...mapState({
      isHighContrastModeActive: state => state.settings.theme === DARK
    }),
    path() {
      return {
        name: 'PortalTariffDetails',
        params: { slug: this.slug }
      }
    },
    iconHeight() {
      return this.$breakpoint.upToDesktop() ? '20' : '24'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../tariff_card';

.card__wrapper {
  list-style: none;
  padding-left: 0;
}

.card__header {
  @include breakpoint($up-to-tablet) {
    padding: 24px 12px;
    flex-direction: column;
    align-items: center;
  }
}

.title__wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include breakpoint($up-to-tablet) {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.card__title {
  margin-bottom: 16px;
  color: var(--root-color-muted);

  @include breakpoint($from-tablet) {
    @include font-bold;

    margin-bottom: 0;
    color: var(--root-color);
  }
}

.card__subtitle {
  @include breakpoint($up-to-tablet) {
    margin-bottom: 24px;
    font-size: 0.875rem;
  }
}

.price__wrapper {
  align-items: flex-start;

  @include breakpoint($up-to-tablet) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  :deep(.price) {
    height: calc-rem(45px);

    .column {
      text-align: start;
    }

    .euro {
      font-size: calc-rem(56px);
    }

    .cent {
      font-size: 1.5rem;
    }
  }
}

.price__wrapper--mobile {
  .price__desc {
    margin-top: 0;
  }
}

.classic_price {
  @include font-bold;

  font-size: min(4rem, 96px);
  line-height: 0.9;
  margin-bottom: 15px;
  &::after {
    content: '€';
    vertical-align: super;
    font-size: min(2rem, 60px);
    margin-left: 4px;
  }
}
</style>
