<template>
  <div class="speech-bubble" :style="{ 'max-width': maxWidth }">
    <svg
      class="speech-bubble__spike"
      :class="{
        'spike--left': left,
        'spike--right': right,
        'spike--top': top,
        'spike--bottom': bottom
      }"
      width="40"
      height="40"
    >
      <path d="M30,0 v30 h-30 L30,0" />
    </svg>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'StageSpeechBubble',
  props: {
    right: {
      type: Boolean,
      default: false
    },
    top: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: '250px'
    }
  },
  computed: {
    left() {
      return !this.right
    },
    bottom() {
      return !this.top
    }
  }
}
</script>

<style lang="scss" scoped>
.speech-bubble {
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;
  color: var(--root-color);
  background-color: var(--background-primary);
  opacity: 0.9;
  font-size: 1rem;
  border-radius: 8px;
  margin: 0 20px;
  padding: 10px 15px;
  position: relative;
  align-items: center;

  @include breakpoint($up-to-tablet) {
    margin-left: 20px;
  }

  @include breakpoint($up-to-mobile) {
    margin-right: 0;
  }

  .speech-bubble__spike {
    position: absolute;

    &.spike--bottom {
      bottom: -10px;
    }

    &.spike--top {
      top: -10px;
      transform: scaleY(-1);
    }

    &.spike--left {
      left: -15px;
    }

    &.spike--right {
      right: -15px;
      transform: scaleX(-1);
    }

    &.spike--top.spike--right {
      transform: scale(-1);
    }

    path {
      fill: var(--background-primary);
    }
  }
}
</style>
