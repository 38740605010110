<template>
  <StageTemplate
    title="Volle Freiheit mit schlauen Prepaid-Tarifen"
    class="svg"
    :color="color"
    :stage-visual="stageVisual"
    :stage-visual-mobile="stageVisualMobile"
    :href="$router.resolve(path).href"
    :animate-elements="animateElements"
    :svg-view-box-height="600"
    :title-margin-top="titleMarginTop"
    :button-class="buttonClass"
  >
  </StageTemplate>
</template>

<script>
// Import the used SVG otherwise it will not land in the prod build!
import StageTemplate from 'src/components/common/stages/templates/background_title_speechbubble_svg.vue'
import StageVisual from './resources/autumn/stagevisual.svg'
import StageVisualMobile from './resources/autumn/stagevisual-mobile.svg'

export default {
  name: 'FonicMobileAutumn',
  components: {
    StageTemplate
  },
  data() {
    return {
      stageVisual: StageVisual,
      stageVisualMobile: StageVisualMobile,
      color: '#ffc13c',
      path: {
        name: 'PortalTariffOverview'
      },
      animateElements: [
        {
          query: '#baum-gross',
          animation: 'shake 15s linear 0s infinite normal none'
        },
        {
          query: '#wolken',
          animation: 'moving-clouds 500s linear 0s infinite normal'
        }
      ]
    }
  },
  computed: {
    titleMarginTop: function () {
      return this.$breakpoint.fromDesktop() ? undefined : '80px'
    },
    buttonClass: function () {
      return this.$breakpoint.fromTablet() ? 'button fncm' : 'button'
    }
  }
}
</script>

<style lang="scss">
.image__wrapper.image_component {
  img {
    .ek {
      display: none;
    }
  }
}
</style>

// These animations need to be globally accessible, otherwise the f-inline-svg
component two layers further down will not be able to // access them
<style lang="scss">
@import 'src/components/common/stages/keyframes';

.inline-svg-container #Planet_Ring {
  filter: opacity(0.2);
}
</style>

<style lang="scss" scoped>
.svg {
  background: var(--autumn);

  @include breakpoint($from-desktop) {
    :deep(.button) {
      margin-bottom: 40px;
    }
  }
}
</style>
