<template>
  <div>
    <Modal
      ref="noticeModal"
      close-button
      :width="800"
      disable-link
      background-close
    >
      <h1 class="title">Erdbeben an der türkisch-syrischen Grenze:</h1>
      <h2 class="subtitle">
        O<sub>2</sub> Telefónica stellt Anrufe und SMS in die Türkei und nach
        Syrien kostenfrei
      </h2>
      <p class="paragraph">
        Die Bilder und Nachrichten, die uns zum Erdbeben in der Türkei und
        Syrien erreichen, machen uns zutiefst betroffen. Unsere Solidarität gilt
        den betroffenen Menschen vor Ort und ihren Angehörigen. Deshalb schalten
        wir vom 9. Februar 2023 bis zum 24. Februar 2023 alle Anrufe im
        Mobilfunk und Festnetz sowie SMS für {{ $brand.name }} aus Deutschland
        in die Türkei und nach Syrien kostenfrei. Zusätzlich werden wir im
        genannten Zeitraum Roaming-Kosten in der Türkei gutschreiben.
      </p>
      <p class="paragraph">
        Damit wollen wir den Menschen helfen, mit ihren Familien und Freunden in
        Verbindung zu bleiben. Auch Helfer vor Ort werden dadurch unterstützt.
        Die Anteilnahme und Hilfsbereitschaft in allen unseren Teams ist enorm.
        Weitere Hilfsmöglichkeiten werden aktuell geprüft.
      </p>
      <p class="paragraph">
        O<sub>2</sub> Telefónica CEO Markus Haas macht deutlich: „Die
        Erdbebenkatastrophe bestürzt mich zutiefst. Umso beeindruckter bin ich
        von der Hilfsbereitschaft und der Unterstützung unserer Teams bei O<sub
          >2</sub
        >
        Telefónica und AY YILDIZ. Ob Spenden, Hilfsmaßnahmen oder Anrufe und
        Nachrichten, die Hoffnung und Zuversicht geben: Jeder Beitrag zählt!
        Lasst uns weiter eng für die Menschen in Not zusammenstehen.“
      </p>
    </Modal>
    <div class="notice__wrapper">
      <p class="notice__paragraph">
        Für {{ $brand.name }}-Kunden sind Gespräche und SMS aus Deutschland in
        die Türkei und Syrien kostenfrei. Roaming-Kosten in der Türkei werden
        nachträglich
        <span class="no-break">
          gutgeschrieben.
          <sup class="super notice__info">
            <ButtonComponent
              ref="modalLink"
              button-type="look-like-link"
              data-cy="notice__open-link"
              :clickhandler="opennoticeModal"
              aria-haspopup="true"
              class="notice__info__button"
            >
              &#9432;
              <span class="visually-hidden">Weitere Informationen</span>
            </ButtonComponent>
          </sup>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import Modal from 'src/components/common/modal/modal.vue'

export default {
  name: 'NoticeComponent',
  components: {
    Modal,
    ButtonComponent
  },
  methods: {
    opennoticeModal() {
      this.$refs.noticeModal.open()
    }
  }
}
</script>

<style lang="scss" scoped>
.notice__wrapper {
  @include full_width_bg;

  display: flex;
  margin-top: -15px;
  margin-bottom: 30px;
  background-color: transparent;

  .notice__paragraph {
    @include full_width;
    @include font_normal;

    background-color: $link-color;
    color: var(--button_primary_label);
    text-align: center;
    line-height: 1.1;
    border-radius: 8px;
    padding: 18px 15px 14px;

    @include breakpoint($up-to-mobile-landscape) {
      line-height: 1.5;
    }
  }

  .notice__info {
    font-size: 90%;
    font-weight: bold;
    top: 0;
  }

  .notice__info__button {
    height: 0.96rem;
    width: 0.91rem;

    &:focus {
      border-radius: 50%;
    }

    :deep(.button-label) {
      color: var(--button_primary_label);
      font-size: 90%;
      font-weight: bold;
    }
  }
}
</style>
